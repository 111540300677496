import { Modal } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'shared/components/Button';
import routes, { appRoute } from 'shared/constants/routes';
import { useTranslation } from "../../../contexts/TranslationContext";

export default function CancelModal() {
  const [isOpen, setIsOpen] = useState(false);
  const { translate } = useTranslation();

  const navigate = useNavigate();

  return (
    <>
      <Button
        onClick={() => setIsOpen(true)}
        color="primary"
        mode="outline"
        className="rounded-xl h-12 w-32">{translate('CREATE_REQUEST.CANCEL')}</Button>
      <Modal
        open={isOpen}
        title={translate('CREATE_REQUEST.CANCEL_REQUEST')}
        onCancel={() => setIsOpen(false)}
        footer={
          <Button
            onClick={() => {
              setIsOpen(false);
              navigate(appRoute(routes.HOME));
            }}
            color="primary"
            className="rounded-xl min-w-32">{translate('CREATE_REQUEST.CONFIRM')}</Button>
        }
      >
        {translate('CREATE_REQUEST.ARE_YOU_SURE_REQUEST')}
      </Modal >
    </>
  );
}
