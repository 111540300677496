export default function gaTrackingEvent(eventName, payload) {
  window.dataLayer.push({
    event: eventName,
    payload,
  })

   window.twq('track',eventName,payload);

  window.ttq?.track(eventName,payload);



  window.fbq('track',eventName,payload);



  window.snaptr('track',eventName,payload);

}


