import { LoadingOutlined } from '@ant-design/icons';
import { Col, Input, Row, Typography } from "antd";
import { useSession } from 'contexts/SessionContext';
import { useTranslation } from 'contexts/TranslationContext';
import { Formik } from 'formik';
import useAuthHook from 'hooks/useAuthHook';
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { useSignInMutation, useVerifyMutation } from 'services/auth';
import { Button } from "shared/components/Button";
import * as LOCAL_STORAGE_KEYS from 'shared/constants/localStorageKeys';
import routes, { appRoute } from "shared/constants/routes";
import { Header } from "shared/views/Header";
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';
import gaTrackingEvent from "../utilities/gaTrackingEvent";
import getGeoLocation from "../utilities/getGeoLocation";
import logApiDetails from '../utilities/logDetails';

const { Text } = Typography;

const schema = Yup.object().shape({
  iqamaId: Yup.string()
    .length(10, 'ID_10_DIGITS')
    .required('CANNOT_BE_EMPTY'),
  password: Yup.string()
    .required('CANNOT_BE_EMPTY'),
});

const SignInForm = ({
  setStep,
  setId
}) => {
  const [signIn, { isLoading }] = useSignInMutation();
  const { translate } = useTranslation();

  const initialValues = {
    iqamaId: '',
    password: '',
  };

  const onSubmit = async (values, { resetForm }) => {
    try {
      const response = await signIn(values).unwrap();
      if (response.isOTPSend) {
        logApiDetails({ url: "/v1/auth/login", statusCode: 200, id: values.iqamaId })

        setId(values.iqamaId);
        setStep('verification');
        toast.success(translate('SIGN_IN.CREDENTIAL_SUCCESS'));
      } else {
        console.log(response, "response");
        logApiDetails({ url: "/v1/auth/login", statusCode: response?.status, id: values.iqamaId })
        resetForm();
        toast.error(response?.message || translate('SIGN_IN.INVALID_CREDENTIALS'));
      }
    } catch (error) {
      console.log(error, "error");

      logApiDetails({ url: "/v1/auth/login", statusCode: error?.status, id: values.iqamaId })
      if (error.data?.message)
        toast.error(error.data?.message)
      else
        toast.error(translate('SIGN_IN.SOMETHING_WENT_WRONG'));
    }
  };

  const gaSignUpEvent = () => {
    gaTrackingEvent('create_account', {});
  }

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}>
      {({ handleSubmit, handleChange, handleBlur, values, errors }) => (
        <form onSubmit={handleSubmit} className="space-y-3 md:space-y-3">
          <div>
            <Input
              className={'tamweel-base-input'}
              name="iqamaId"
              id="iqamaId"
              placeholder={translate('SIGN_IN.FIELDS.ID')}
              maxLength={10}
              onChange={(e) => {
                // Remove any character that is not an English letter or number
                const value = e.target.value.replace(/[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF\u0660-\u0669]/g, '');
                handleChange({
                  target: {
                    name: "iqamaId",
                    value: value,
                  },
                });
              }}
              value={values.iqamaId} />
            {
              errors.iqamaId && <Text type="danger">{translate("SIGN_IN." + errors.iqamaId)}</Text>
            }
          </div>
          <div>
            <Input.Password
              className={'tamweel-base-input'}
              status={errors.password ? "error" : null}
              name="password"
              id="password"
              placeholder={translate('SIGN_IN.FIELDS.PASSWORD')}
              onChange={(e) => {
                const value = e.target.value.replace(/[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF\u0660-\u0669]/g, '');
                handleChange({
                  target: {
                    name: "password",
                    value: value,
                  },
                });
              }}
              value={values.password} />
            {
              errors.password && <Text type="danger">{translate("SIGN_IN." + errors.password)}</Text>
            }
          </div>
          <div className="text-center  text-sm">
            <Link style={{ fontSize: '14px' }} to={routes.AUTH.FORGOT_PASSWORD}>{translate('SIGN_IN.FORGOT_PASSWORD')}</Link>
          </div>
          <div style={{ fontSize: '14px' }} className="uppercase text-center text-sm text-gray-400">
            <span>{translate('SIGN_IN.DONT_HAVE_AN_ACCOUNT')} </span>
            <Link className="text-primary" to={routes.AUTH.SIGN_UP} onClick={gaSignUpEvent}>{translate('SIGN_IN.SIGN_UP')}</Link>
          </div>
          <Button
            className={'tamweel-button mb-4'}
            type="submit" disabled={isLoading} block>
            {
              isLoading ? <LoadingOutlined /> : translate('SIGN_IN.LOGIN')
            }
          </Button>
        </form>
      )}
    </Formik>
  );
};

const SignInVerification = ({ setStep, id }) => {
  const [seconds, setSeconds] = useState(60);
  const [otp, setOtp] = useState(['', '', '', '']);
  const [lat, setLat] = useState(null);
  const [lang, setLang] = useState(null);

  const { signIn } = useSession();

  // Device ID handling
  let deviceId = localStorage.getItem(LOCAL_STORAGE_KEYS.DEVICE_ID);
  if (!deviceId) {
    deviceId = uuidv4();
    localStorage.setItem(LOCAL_STORAGE_KEYS.DEVICE_ID, deviceId);
  }

  const otpRef1 = useRef(null);
  const otpRef2 = useRef(null);
  const otpRef3 = useRef(null);
  const otpRef4 = useRef(null);

  const [verify, { isLoading }] = useVerifyMutation();
  const navigate = useNavigate();
  const { translate } = useTranslation();
  const { language } = useTranslation();

  const countdown = (intervalId) => {
    if (seconds <= 0) {
      setStep('form');
      toast.error(translate('SIGN_IN.TIMEOUT'));
      clearInterval(intervalId);
    } else {
      setSeconds(seconds - 1);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      countdown(intervalId);
    }, 1000);

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [seconds]); // eslint-disable-line

  // Get geolocation when component mounts
  useEffect(() => {

    getGeoLocation((coords) => {
      if (coords.latitude && coords.longitude) {
        setLat(coords.latitude);
        setLang(coords.longitude);
      }
    });
  }, []);

  const handleChange = (value, index) => {
    setOtp((otp) => otp.map((_, i) => (index === i ? value : _)));

    switch (index) {
      case 0:
        otpRef2.current.focus();
        break;
      case 1:
        otpRef3.current.focus();
        break;
      case 2:
        otpRef4.current.focus();
        break;
      default:
        return;
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await verify({
        otp: otp.join(''),
        iqamaId: id,
        deviceId,
        ObjLatLng: {
          lat: lat !== null ? lat : '',
          lang: lang !== null ? lang : '',
        },
      }).unwrap();

      if (response?.verified) {
        logApiDetails({ url: "/v1/auth/verify", statusCode: 200, id })
        signIn(response?.user);
        navigate(appRoute(routes.HOME));
        let userObj = response.user;
        userObj = {
          ...userObj,
          token: response.token,
        };

        localStorage.setItem(LOCAL_STORAGE_KEYS.USER, JSON.stringify(userObj));
        toast.success(translate('SIGN_IN.VERIFICATION_COMPLETE'));
      } else {
        logApiDetails({ url: "/v1/auth/verify", statusCode: 400, id })
        toast.error(translate('SIGN_IN.WRONG_OTP'));
        setStep('form');
      }
    } catch (error) {
      logApiDetails({ url: "/v1/auth/verify", statusCode: 400, id })
      toast.error(translate('SIGN_IN.SOMETHING_WENT_WRONG'));
    }
  };

  return (
    <>
      <div className="text-gray-400">{translate('SIGN_IN.WE_HAVE_SENT')}</div>
      <form onSubmit={onSubmit} className="space-y-4 md:space-y-6">
        <div>
          <Row gutter={[12, 12]} dir="ltr">
            <Col span={6}>
              <input
                ref={otpRef1}
                maxLength="1"
                onChange={(e) => handleChange(e.target.value, 0)}
                type="number"
                name="otp1"
                id="otp1"
                className="text-center bg-white border border-[#EFEFEF] text-gray-900 sm:text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-2.5"
                required
              />
            </Col>
            <Col span={6}>
              <input
                ref={otpRef2}
                maxLength="1"
                onChange={(e) => handleChange(e.target.value, 1)}
                type="number"
                name="otp1"
                id="otp1"
                className="text-center bg-white border border-[#EFEFEF] text-gray-900 sm:text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-2.5"
                required
              />
            </Col>
            <Col span={6}>
              <input
                ref={otpRef3}
                maxLength="1"
                onChange={(e) => handleChange(e.target.value, 2)}
                type="number"
                name="otp1"
                id="otp1"
                className="text-center bg-white border border-[#EFEFEF] text-gray-900 sm:text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-2.5"
                required
              />
            </Col>
            <Col span={6}>
              <input
                ref={otpRef4}
                maxLength="1"
                onChange={(e) => handleChange(e.target.value, 3)}
                type="number"
                name="otp1"
                id="otp1"
                className="text-center bg-white border border-[#EFEFEF] text-gray-900 sm:text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-2.5"
                required
              />
            </Col>
          </Row>
        </div>
        <Button type="submit" disabled={isLoading} block>
          {isLoading ? <LoadingOutlined /> : translate('SIGN_IN.SEND')}
        </Button>
        <div className="font-bold text-3xl text-center text-gray-400">00:{String(seconds).padStart(2, '0')}</div>
        <div className="cursor-not-allowed text-center text-gray-400 font-bold text-sm">
          {translate('SIGN_IN.RESEND_CODE')}
        </div>
      </form>
    </>
  );
};

export default function SignIn() {
  const [step, setStep] = useState('form');
  const [id, setId] = useState(null);
  const { logout } = useAuthHook();
  const { translate } = useTranslation();

  useEffect(() => {
    logout();
  }, []);

  return (
    <section className="bg-primary with-splash-bg h-screen">
      <div className="relative">
        <Header />
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <div className="  md:mt-0 sm:max-w-md">
            <div className="auth_form_wrapper login-form">
              <h1 className="form-header-text tamweel-text-22 font-bold leading-tight tracking-tight text-primary uppercase ">
                {translate('SIGN_IN.TITLE')}
              </h1>
              <div className="bg-secondary w-16 h-1"></div>
              <div className='form-header-title-wrapper'>
                <h2 className="uppercase text-black text-3xl font-bold">
                  <div>{translate('SIGN_IN.DESCRIPTION')}</div>
                </h2>
              </div>

              {
                step === 'form' && <SignInForm setId={setId} setStep={setStep} />
              }
              {
                step === 'verification' && <SignInVerification id={id} setStep={setStep} />
              }
              <p className='mt-9 w-50 text-xs text-center'>{translate("SIGN_IN.HELP_LINE")}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
