import * as Yup from "yup";
export const personalDetailsSchema = ({
	buildingNumFlag = false,
	streetNameFlag = false,
	district_nameFlag = false,
	additionalNumberFlag = false,
	postCodeFlag = false,
	titleOptions = [],
	dependentOptions = [],
	maritalStatus = [],
	nationalities = [],
	regions = [],
	cities = [],
	onNationalityChange = () => { },
	onMaritalStatusChange = () => { },
}) =>
	[
		{
			type: "text",
			key: "fullName",
			label: "Full_Name",
			span: 24,
			disabled: true,
		},
		// {
		// 	type: "text",
		// 	key: "address",
		// 	label: "Address",
		// 	span: 24,
		// 	disabled: true,
		// },
		{
			type: "text",
			key: "streetName",
			label: "Street_Name",
			span: 24,
			required: !streetNameFlag,
			disabled: streetNameFlag,
			validation: Yup.string()
				.required('street_name_required'),
		},
		{
			type: "text",
			key: "buildingNum",
			label: "Building_Num",
			span: 24,
			required: !buildingNumFlag,
			disabled: buildingNumFlag,
			validation: Yup.string()
				.required('buildingnum_required'),
		},
		{
			type: "text",
			key: "additionalNumber",
			label: "Additional_Num",
			span: 24,
			required: !additionalNumberFlag,
			disabled: additionalNumberFlag,
			validation: Yup.string()
				.required('additional_name_required'),
		},
		{
			type: "text",
			key: "district_name",
			label: "District_Name",
			span: 24,
			required: !district_nameFlag,
			disabled: district_nameFlag,
			validation: Yup.string()
				.required('District_name_required'),
		},
		{
			type: "text",
			key: "postCode",
			label: "Post_Code",
			span: 8,
			required: !postCodeFlag,
			disabled: postCodeFlag,
			validation: Yup.string()
				.required('Post_code_required'),
		},
		{
			type: "text",
			key: "idExpiryDate",
			label: "ID_Expiry_Date",
			span: 8,
			disabled: true,
		},
		{
			type: "select",
			key: "region",
			label: "Region",
			required: true,
			options: regions,
			span: 8,
		},
		{
			type: "select",
			key: "city",
			label: "City",
			span: 8,
			options: cities,
			disabled: false,
			required: true,
		},
		{
			type: "select",
			key: "maritalStatus",
			label: "Marital_status",
			span: 8,
			required: true,
			options: maritalStatus,
			validation: Yup.string().required("Select_Marital"),
		},
		{
			type: "select",
			key: "title",
			label: "Title",
			required: true,
			options: titleOptions,
			span: 8,
			value: "1007",
		},
		{
			type: "select",
			key: "numberOfDependents",
			label: "Number_of_dependents",
			span: 8,
			options: dependentOptions,
			required: true,
			validation: Yup.string().required("Pick_Dependents"),
		},
		{
			type: "select",
			key: "nationality",
			label: "Nationality",
			span: 8,
			required: true,
			options: nationalities,
			props: {
				showSearch: true,
				filterOption: (input, option) =>
					option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0,
				onChange: onNationalityChange,
			},
		},

		// {
		// 	type: "text",
		// 	key: "IBAN",
		// 	label: "IBAN",
		// 	span: 10,
		// 	addonBefore: "SA",
		// 	props: {
		// 		maxLength: 22,
		// 	},
		// 	required: true,
		// 	validation: Yup.string()
		// 		.required("CANNOT_BE_EMPTY")
		// 		.length(22, "IBAN_22")
		// 		.matches(/^[0-9]+$/, "IBAN_ONLY_NUMBERS"),
		// },
		{
			type: "radio",
			key: "gender",
			label: "Gender",
			required: true,
			options: [
				{ label: "Male", labelAr: "ذكر", value: "M" },
				{ label: "Female", labelAr: "أنثى", value: "F" },
			],
			span: 24,
			value: "male",
			validation: Yup.string().required('Gender is required'),
		},
	];

export const employmentDetailsSchema = ({ hideRealBenificiaryName = true, employmentTypes = [], residentStatuses = [], educationLevels = [], employmentSectors = [] }) => [
	{
		type: "select",
		key: "empCatId",
		label: "Employment_type",
		span: 8,
		options: employmentTypes /* [
      { label: 'Retired / Pensioner', value: '1' },
      { label: 'Salaried', value: '2' },
      { label: 'Business', value: '3' },
    ], */,
		required: true,
		validation: Yup.string().required('Employment type is required'),
	},
	{
		type: "select",
		key: "qualificationId",
		label: "Education_Level",
		span: 8,
		options: educationLevels /* [
      {
        value: '10000487',
        label: 'Living With Parents/ Relative',
        labelAr: 'سكن مع الوالدين/اقارب',
      },

    ], */,
		required: true,
		validation: Yup.string().required('Education level is required'),
	},
	{
		type: "select",
		key: "p_residentId",
		label: "Resident_Status",
		span: 8,
		options: residentStatuses,
		required: true,
		validation: Yup.string().required('Resident status is required'),
	},
	{
		type: "select",
		key: "sectorId",
		label: "Employment_Sector",
		span: 8,
		options: employmentSectors,
		required: true,
		validation: Yup.string().required('Employment sector is required'),
	},
	{
		type: "datePicker",
		key: "dateOfJoining",
		label: "Joining_Date",
		span: 8,
	},
	{
		type: "select",
		key: "pepRelation",
		label: "PEP_Relation",
		span: 15,
		options: [
			{ value: 'Yes', labelAr: "نعم", label: "Yes" },
			{ value: "No", labelAr: "لا", label: "No" },
		],
		// value: "No",
		required: true,
		validation: Yup.string().required('pep_relation_required')
	},
	{
		type: "select",
		key: "realBenificiary",
		label: "Real_Benificiary",
		span: 9,
		options: [
			{ value: 'Yes', labelAr: "نعم", label: "Yes" },
			{ value: "No", labelAr: "لا", label: "No" },
		],
		// value: "No",
		required: true,
		validation: Yup.string().required('real_benificiary_required')
	},
	{
		type: "text",
		key: "realBenificiaryName",
		label: "Real_Benificiary_Name",
		span: 15,
		required: !hideRealBenificiaryName,
		disabled: hideRealBenificiaryName,
		validation: Yup.string()
			.when('realBenificiary', (realBenificiary, schema) => {
				if (realBenificiary[0] === 'No') {
					return schema.required("CANNOT_BE_EMPTY").notOneOf([''], "CANNOT_BE_EMPTY")
				}
				return schema.notRequired();
			})
	},

	// ...(
	//   isSalaried
	//     ? [
	//       {
	//         type: 'text',
	//         key: 'employerName',
	//         label: 'Employer name',
	//         span: 8,
	//       },
	//       {
	//         type: 'select',
	//         key: 'customerType',
	//         label: 'Customer type',
	//         span: 8,
	//       },
	//       {
	//         type: 'select',
	//         key: 'jobPosition',
	//         label: 'Job position',
	//         span: 8,
	//       },
	//       {
	//         type: 'date',
	//         key: 'joiningDate',
	//         label: 'Joining date',
	//         span: 8,
	//       },
	//       {
	//         type: 'number',
	//         key: 'currentExperience',
	//         label: 'Current experience (in months)',
	//         span: 8,
	//       },
	//       {
	//         type: 'number',
	//         key: 'totalExperience',
	//         label: 'Total experience (in months)',
	//         span: 8,
	//         disabled: true,
	//       },
	//     ]
	//     : []
	// ),
];

export const loanDetailsSchema = ({ hideRequestedLoanAmount = true, disableSubProduct = true, productsList = [], subProductsList = [] }) => [
	// let arr = [
	{
		type: "select",
		key: "productId",
		label: "Type_of_loan",
		span: 8,
		options: productsList,
		required: true,
		validation: Yup.string().required("CANNOT_BE_EMPTY"),
	},
	{
		type: "select",
		key: "subproductId",
		label: "Facility",
		span: 8,
		options: subProductsList,
		required: true,
		disabled: disableSubProduct,
		validation: Yup.string().required("CANNOT_BE_EMPTY"),
	},
	{
		type: "number",
		key: "request_amount",
		label: "Requested_loan_amount",
		span: 8,
		required: !hideRequestedLoanAmount,
		disabled: hideRequestedLoanAmount,
		validation: Yup.number()
			.when('productId', (productId, schema) => {
				const parsed = productId[0] ? JSON.parse(productId[0]) : null;
				if (parsed && parsed.PRODUCTID === 328) {
					return schema.required("CANNOT_BE_EMPTY")
						.integer("MUST_BE_INTEGER")
						.min(2500, "MUST_BE_ATLEAST_2500")
						.max(500000, "CANNOT_EXCEED_500000")
				}
				return schema.notRequired();

			})
	},
	{
		type: "select",
		key: "tenure",
		label: "Tenure",
		options: [
			{ value: "12", label: "12" },
			{ value: "24", label: "24" },
			{ value: "36", label: "36" },
			{ value: "48", label: "48" },
			{ value: "60", label: "60" },
		],
		value: 12,
		span: 8,
		validation: Yup.string()
			.required("CANNOT_BE_EMPTY")
			.when('request_amount', (request_amount, schema) => {
				if (request_amount >= 2500 && request_amount <= 5000) {
					return schema.test(
						'max1',
						'MAX_12_MONTHS',
						value => { return parseInt(value, 10) <= 12 }
					);
				} else if (request_amount >= 5001 && request_amount <= 20000) {
					return schema.test(
						'max2',
						'MAX_36_MONTHS',
						value => parseInt(value, 10) <= 36
					);
				} else if (request_amount >= 20001) {
					return schema.test(
						'max3',
						'MAX_60_MONTHS',
						value => parseInt(value, 10) <= 60
					);
				}
				return schema; // default case if none of the conditions are met
			}),
	},
	{
		type: "text",
		key: "IBAN",
		label: "IBAN",
		span: 10,
		addonBefore: "SA",
		props: {
			maxLength: 22,
		},
		required: !hideRequestedLoanAmount,
		disabled: hideRequestedLoanAmount,
		validation: Yup.string()
			.when('productId', (productId, schema) => {
				const parsed = productId[0] ? JSON.parse(productId[0]) : null;
				if (parsed && parsed.PRODUCTID === 328) {
					return schema.required("CANNOT_BE_EMPTY")
						.length(22, "IBAN_22")
						.matches(/^[0-9]+$/, "IBAN_ONLY_NUMBERS")
				}
				return schema.notRequired()
			})
	},
	// ];
	// 	if (hideRequestedLoanAmount) {
	// 	// arr[2] = {
	// 	// 	...arr[2],
	// 	// 	disabled: true
	// 	// }
	// 	arr = arr.filter(field => field.key !== "request_amount");
	// }
	// return arr;
];

export const financialDetailsSchema = ({ hasAdditionalIncome = false, hasExistingLoan = false }) => [
	{
		type: "number",
		key: "foodExpenses",
		label: "Food_expenses",
		span: 8,
		validation: Yup.number().integer("MUST_BE_INTEGER").min(0, "MUST_BE_ATLEAST_0").max(9999, "CANNOT_EXCEED_4").required("CANNOT_BE_EMPTY"),
	},
	{
		type: "number",
		key: "housingExpenses",
		label: "Housing_expenses",
		span: 8,
		validation: Yup.number().integer("MUST_BE_INTEGER").min(0, "MUST_BE_ATLEAST_0").max(9999, "CANNOT_EXCEED_4").required("CANNOT_BE_EMPTY"),
	},
	{
		type: "number",
		key: "domesticWorkers",
		label: "Domestic_workers",
		span: 8,
		validation: Yup.number().required("CANNOT_BE_EMPTY").integer("MUST_BE_INTEGER").min(0, "MUST_BE_ATLEAST_0").max(9999, "CANNOT_EXCEED_4"),
	},
	{
		type: "number",
		key: "educationExpenses",
		label: "Education_expenses",
		span: 8,
		validation: Yup.number().required("CANNOT_BE_EMPTY").integer("MUST_BE_INTEGER").min(0, "MUST_BE_ATLEAST_0").max(9999, "CANNOT_EXCEED_4"),
	},
	{
		type: "number",
		key: "healthCareExpenses",
		label: "Health_care_expenses",

		span: 8,
		validation: Yup.number().required("CANNOT_BE_EMPTY").integer("MUST_BE_INTEGER").min(0, "MUST_BE_ATLEAST_0").max(9999, "CANNOT_EXCEED_4"),
	},
	{
		type: "number",
		key: "commAndTransExpenses",

		label: "Communication_and_transportation_expenses",
		span: 8,
		validation: Yup.number().required("CANNOT_BE_EMPTY").integer("MUST_BE_INTEGER").min(0, "MUST_BE_ATLEAST_0").max(9999, "CANNOT_EXCEED_4"),
	},
	{
		type: "number",
		key: "insuranceExpenses",

		label: "Insurance_expenses",
		span: 8,
		validation: Yup.number().required("CANNOT_BE_EMPTY").integer("MUST_BE_INTEGER").min(0, "MUST_BE_ATLEAST_0").max(9999, "CANNOT_EXCEED_4"),
	},
	{
		type: "number",
		key: "anyExpectedFutureExpense",

		label: "Any_expected_future_expenses",
		span: 8,
		validation: Yup.number().required("CANNOT_BE_EMPTY").integer("MUST_BE_INTEGER").min(0, "MUST_BE_ATLEAST_0").max(9999, "CANNOT_EXCEED_4"),
	},
	{
		type: "text",
		key: "totalExpenses",

		label: "Total_expenses",
		span: 8,
		disabled: true,
		validation: Yup.string(),
	},
];

export const autoLoanSchema = ({ manufacturerList = [], modelList = [], modelTypeList = [], dealerList = [], colorList = [], conditionList = [], yearList = [] }) => [
	{
		type: "select",
		key: "assetManufacturer",
		label: "Asset_manufacturer",
		searchable: true,
		span: 8,
		options: manufacturerList,
		required: true,
		validation: Yup.string().required("CANNOT_BE_EMPTY"),
	},
	{
		type: "select",
		key: "assetModel",
		label: "Asset_model",
		searchable: true,
		span: 8,
		options: modelList,
		required: true,
		validation: Yup.string().required("CANNOT_BE_EMPTY"),
	},
	{
		type: "select",
		key: "assetModelType",
		label: "Asset_model_type",
		searchable: true,
		span: 8,
		options: modelTypeList,
		required: true,
		validation: Yup.string().required("CANNOT_BE_EMPTY"),
	},
	{
		type: "select",
		key: "colorId",
		label: "Asset_color",
		span: 8,
		options: colorList,
		required: true,
	},
	{
		type: "select",
		key: "assetCondition",
		label: "Asset_condition",
		span: 8,
		required: true,
		options: conditionList,
		validation: Yup.string().required("CANNOT_BE_EMPTY"),

	},
	{
		type: "number",
		key: "assetPrice",
		label: "Asset_price",
		span: 8,
		required: true,
		validation: Yup.number().integer("MUST_BE_INTEGER").min(25000, "MUST_BE_ATLEAST").max(9999999, "CANNOT_EXCEED_7"),
	},
	{
		type: "select",
		key: "assetModelYear",
		label: "Asset_model_year",
		span: 8,
		required: true,
		options: yearList,
		validation: Yup.string().required("CANNOT_BE_EMPTY"),
	},
	{
		type: "number",
		key: "downPayment",
		label: "Down_payment",
		span: 8,
		required: true,
		validation: Yup.number()
			.integer("MUST_BE_INTEGER")
			.test("is-less-than-20-percen", "DOWN_PAYMENT_20", function (value) {
				const { assetPrice } = this.parent;
				return value === 0 || (value >= 3000 && value < assetPrice * 0.2);
			})
			.required("DOWN_PAYMENT_REQUIRED"),
	},
	{
		type: "number",
		key: "ballonPayment",
		label: "Ballon_payment",
		span: 8,
		required: true,
		validation: Yup.number()
			.integer("MUST_BE_INTEGER")
			.test("is-less-than-20-percent", "BALLON_PAYMENT_40", function (value) {
				const { assetPrice } = this.parent;
				return value <= assetPrice * 0.4;
			})
			.required("BALLON_REQUIRED"),
	},
	{
		type: "select",
		key: "dealerId",
		label: "Dealer_name",
		span: 8,
		options: dealerList,
		required: true,
		validation: Yup.string().required("CANNOT_BE_EMPTY"),
	},
];

export const documentDetailsSchema = [
	{
		type: "file",
		key: "Document",
		label: "",
		span: 24,
	},
];

export const newGuarantorSchema = [
	{
		type: "text",
		key: "firstName",
		label: "First_name",
		span: 8,
		required: true,
		validation: Yup.string().required("NAME_REQUIRED"),
	},
	{
		type: "text",
		key: "middleName",
		label: "Middle_name",
		span: 8,
		required: true,
		validation: Yup.string().required("NAME_REQUIRED"),
	},
	{
		type: "text",
		key: "familyName",
		label: "Family_name",
		span: 8,
		required: true,
		validation: Yup.string().required("NAME_REQUIRED"),
	},
	{
		type: "select",
		key: "idType",
		label: "ID_type",
		span: 8,
	},
	{
		type: "number",
		key: "idNumber",
		label: "ID_number",
		span: 8,
	},
	{
		type: "number",
		key: "phone",
		label: "Phone",
		span: 8,
		required: true,
		validation: Yup.string().required("PHONE_REQUIRED"),
	},
	{
		type: "text",
		key: "email",
		label: "Email",
		span: 8,
	},
];

export const guarantorsDetailsSchema = [
	{
		type: "file",
		key: "guarantor",
		label: "",
		span: 24,
	},
];
